
import { defineComponent, ref, nextTick, onMounted } from 'vue';
import { ElMessageBox, ElMessage, ElTable } from 'element-plus';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { ResponseError, RoleIdOptions, getAdmins, MAX_PER_PAGE } from '@/services/api';
import { useRoles, useDeleteRole, useUpdateRole, useUpdateRoleToAdmins } from '@/composables/api';
import useFormErrors from '@/utils/validate';
import { PartialRole, Role } from '@/interfaces/Role';
import { Admin, PartialAdmin } from '@/interfaces/Admin';
import { AdminsData } from '@/services/api/interfaces';
import PermissionsTree from './components/PermissionsTree.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const DEFAULT_PERMISSIONS_VALUES: Omit<PartialRole, 'permissions'> & { permissions: number[] } = {
  name: '',
  permissions: []
};

const DEFAULT_ADMINS_VALUES: AdminsData = {
  admins: []
};

export default defineComponent({
  components: {
    SearchBox,
    PermissionsTree,
    permissionUnits
  },
  setup() {
    const { formErrors } = useFormErrors();
    const permissionsValues = ref({ ...DEFAULT_PERMISSIONS_VALUES });
    const adminsValues = ref({ ...DEFAULT_ADMINS_VALUES });

    const page = ref(1);
    const keyword = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const route = useRoute();

    const currentRow = ref<Role>();
    const dialogPermissionsVisible = ref(false);
    const dialogAdminsVisible = ref(false);
    const checkedPermissions = ref([]);
    const isEditPermission = ref();
    const multipleSelection = ref([]);
    const AdminsTable = ref<InstanceType<typeof ElTable>>();
    const { t } = useI18n();

    const { data, isLoading, isFetching, refetch } = useRoles({ page, keyword, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteRole();
    const { mutate: updateRole } = useUpdateRole();
    const { mutate: updateRoleToAdmins } = useUpdateRoleToAdmins();

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const admins = ref<Admin[]>([]);

    onMounted(async() => {
      admins.value = (await getAdmins({ query: { perPage: MAX_PER_PAGE } })).data;

      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const statusIndex = ref(undefined);

    // Tree default
    const defaultExpandedKeys = ref([]);
    const defaultCheckedKeys = ref([]);

    // Open Permissions Dialog
    const openPermissionsDialog = (row: Role) => {
      currentRow.value = row;
      const permissions = row.permissions.map(p => p.id);
      defaultExpandedKeys.value = permissions;
      defaultCheckedKeys.value = permissions;
      dialogPermissionsVisible.value = true;
    };

    // Close Permissions Dialog
    const closePermissionsDialog = () => {
      dialogPermissionsVisible.value = false;
    };

    const getCheckedKeys = (checkedKeys: number[]) => {
      checkedPermissions.value = checkedKeys;
    };

    const savePermissionsChange = () => {
      formErrors.value = {};
      permissionsValues.value = {
        permissions: checkedPermissions.value
      };

      updateRole({
        roleId: `${currentRow.value.id}`,
        data: {
          // TODO: name should be optional
          ...permissionsValues.value,
          name: currentRow.value.name
        }
      }, {
        onSuccess() {
          refetch.value();
          ElMessage.success('Save successfully!');
          closePermissionsDialog();
        },

        onError(error: ResponseError) {
          ElMessage.error(error.response?.data.message);
          formErrors.value = error.response?.data.errors;
          closePermissionsDialog();
        }
      });
    };

    // Default checked admins for the role
    const defaultCheckedAdmins = async(row: Role) => {
      await nextTick();

      if (row) {
        row.users.forEach((user) => {
          const targetAdmin = admins.value.find((admin) => admin.id === user.id);

          AdminsTable.value.toggleRowSelection(targetAdmin, true);
        });
      }
    };

    // Open Admins Dialog
    const openAdminsDialog = (row: Role) => {
      dialogAdminsVisible.value = true;
      currentRow.value = row;

      defaultCheckedAdmins(row);
    };

    const handleSelectionChange = (val: PartialAdmin[]) => {
      multipleSelection.value = val;
    };

    const getAdminIds = (): Array<number> => {
      return multipleSelection.value.map((item) => item.id);
    };

    const closeAdminsDialog = () => {
      dialogAdminsVisible.value = false;
    };

    const saveAdminsChange = () => {
      formErrors.value = {};
      adminsValues.value = {
        admins: getAdminIds()
      };

      updateRoleToAdmins({
        roleId: `${currentRow.value.id}`,
        data: adminsValues.value
      }, {
        onSuccess() {
          refetch.value();
          ElMessage.success('Save successfully!');
          closeAdminsDialog();
        },

        onError(error: ResponseError) {
          ElMessage.error(error.response?.data.message);
          formErrors.value = error.response?.data.errors;
          closeAdminsDialog();
        }
      });
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const deleteRole = ({ roleId }: RoleIdOptions, index: number) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { roleId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const isDisabled = (row) => {
      if (!isEditPermission.value) {
        return false;
      } else {
        return true;
      }
    };

    return {
      page,
      data,
      admins,
      isLoading,
      isFetching,
      statusIndex,
      AdminsTable,
      isEditPermission,
      isDeletedLoading,
      defaultCheckedKeys,
      defaultExpandedKeys,
      dialogAdminsVisible,
      dialogPermissionsVisible,
      isDisabled,
      sortChange,
      deleteRole,
      searchKeyword,
      getCheckedKeys,
      openAdminsDialog,
      saveAdminsChange,
      closeAdminsDialog,
      handleSelectionChange,
      openPermissionsDialog,
      savePermissionsChange,
      t,
      closePermissionsDialog
    };
  }
});
